import FormSelectField from './FormSelectField';
import { DropdownOption } from 'models/DropdownOption';
import { Region } from 'models/Region';
import FormTextField from './FormTextField';

interface Props {
  regions: Region[];
}

const RegionSelector = ({ regions }: Props) => {
  const dropdownOptions: DropdownOption[] = regions?.map((region: Region) => {
    const option: DropdownOption = { label: region.name, value: region.code };
    return option;
  });

  // Display textbox for countries with no regions.
  if (regions?.length === 0) {
    return <FormTextField label="State / Region" name="region" maxChars={64} />;
  }

  // Add "Select a state" as the default option at the top of the list.
  const defaultOption: DropdownOption = { label: 'Select a state', value: '' };
  dropdownOptions?.unshift(defaultOption);

  return <FormSelectField label="State / Region" name="region" options={dropdownOptions} />;
};

export default RegionSelector;
