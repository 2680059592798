import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { REGIONS_URL } from 'constants/urls';
import { Region } from 'models/Region';
import { useURLParams } from 'hooks/useURLParams';

// This hook is used retrieve regions/states of a particular country.
export const useRegions = (countryCode: string) => {
  const transactionId = useURLParams('transaction_id');

  const { status, data } = useQuery(['regions', countryCode], () =>
    FetchAPI.get(`${REGIONS_URL}/${countryCode}/${transactionId}/`),
  );

  // Order by region name
  const regions: Region[] = data?.sort((regionA: Region, regionB: Region) => regionA.name.localeCompare(regionB.name));

  return { status, regions };
};
