import { Box, Modal } from '@material-ui/core';

interface Props {
  open: boolean;
  handleClose: (event: object, reason: string) => void;
  height?: number;
  width?: number;
  children?: React.ReactNode;
}

const BaseModal = ({ open, handleClose, children, height = 400, width = 400 }: Props) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: height,
    height: width,
    bgcolor: 'background.white',
    border: 'none',
    boxShadow: 0,
    p: 0,
  };

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default BaseModal;
