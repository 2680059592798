import { Box, styled } from '@material-ui/core';
import { useTransactionDetails } from 'hooks/useTransactionDetails';
import StripeContainer from 'components/StripeContainer';
import { CybersourceProvider } from 'contexts/CybersourceContext';
import StripeUpdatePaymentPage from './StripeUpdatePaymentPage';
import CybersourceUpdatePaymentPage from './CybersourceUpdatePaymentPage';
import { useBillingInfo } from 'hooks/useBillingInfo';
import { CybersourceFormikInitialBillingValues, FormikBillingValues } from 'models/Billing';
import { BillingInfo, CybersourceBillingInfo } from 'models/BillingInfo';
import CybersourceContainer from 'components/CybersourceContainer';

export const StyledBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  paddingTop: '10px',
  justifyContent: 'flex-end',
});

const UpdatePaymentInfoPage = () => {
  const { transactionDetails } = useTransactionDetails();
  const { billingInfo } = useBillingInfo();

  if (!transactionDetails || !billingInfo) {
    return null;
  }

  const initialValues: FormikBillingValues = {
    cardholderName: (billingInfo as BillingInfo).cardholder_name,
    billingAddress: (billingInfo as BillingInfo).address1,
    billingAddress2: (billingInfo as BillingInfo).address2,
    country: billingInfo.country,
    region: billingInfo.state,
    city: billingInfo.city,
    zip: billingInfo.zip,
  };

  const cybersourceInitialValues: CybersourceFormikInitialBillingValues = {
    firstName: (billingInfo as CybersourceBillingInfo).billing_first_name,
    lastName: (billingInfo as CybersourceBillingInfo).billing_last_name,
    email: (billingInfo as CybersourceBillingInfo).email,
    billingAddress: (billingInfo as CybersourceBillingInfo).address1,
    billingAddress2: (billingInfo as CybersourceBillingInfo).address2,
    country: billingInfo.country,
    region: billingInfo.state,
    city: billingInfo.city,
    zip: billingInfo.zip,
    accountFirstName: (billingInfo as CybersourceBillingInfo).first_name,
    accountLastName: (billingInfo as CybersourceBillingInfo).last_name,
    phoneNumber: (billingInfo as CybersourceBillingInfo).telephone,
    company: (billingInfo as CybersourceBillingInfo).company,
    accountEmail: (billingInfo as CybersourceBillingInfo).account_email,
  };

  return transactionDetails?.payment_gateway === 'stripe' ? (
    <StripeContainer>
      <StripeUpdatePaymentPage initialValues={initialValues} portalInvoicePageUrl={transactionDetails.redirect_url} />
    </StripeContainer>
  ) : (
    <CybersourceContainer>
      <CybersourceProvider>
        <CybersourceUpdatePaymentPage
          initialValues={cybersourceInitialValues}
          portalInvoicePageUrl={transactionDetails.redirect_url}
        />
      </CybersourceProvider>
    </CybersourceContainer>
  );
};

export default UpdatePaymentInfoPage;
