import { useCybersource } from 'hooks/useCybersource';
import { CybersourceLogging } from 'models/Billing';
import { Transaction } from 'models/Transaction';
import { TransactionDetails } from 'models/TransactionDetails';

const setSegmentCookie = (transaction: Transaction, transactionDetails: TransactionDetails) => {
  try {
    const WPESegmentBlob = encodeURIComponent(
      JSON.stringify({
        transaction_id: transaction.id,
        coupon: transaction.coupon,
        currency: transaction.currency,
        first_name: transaction.first_name,
        includes_genesis_pro: includesAddon(transaction, 'genesis-pro'),
        includes_global_edge_security: includesAddon(transaction, 'global-edge-security'),
        includes_page_speed_boost: includesAddon(transaction, 'page-speed-boost'),
        includes_site_monitoring: includesAddon(transaction, 'monitoring-site'),
        includes_smart_plugin_manager: includesAddon(transaction, 'smart-plugin-manager'),
        last_name: transaction.last_name,
        plan: transaction.cart_summary.plan.description,
        subscription: transaction.recurrence.toLowerCase(),
        subtotal: currencyStringToNumber(transaction.cart_summary.total),
        signup_method: transactionDetails.order_channel,
        num_additional_sites: transaction.addons?.find((addon) => addon.item === 'sites-addon')?.qty ?? 0,
        // marketing_blob defaults to {} at the DB data layer, so should be safe to spread here
        ...transaction.marketing_blob,
      }),
    );
    document.cookie = `WPESegmentBlob=${WPESegmentBlob}; domain=.wpengine.com`;
  } catch (error) {
    console.error("couldn't build segment payload");
  }
};

const includesAddon = (transaction: Transaction, sku: string): boolean => {
  return transaction.addons?.filter((addon) => addon.item.includes(sku)).length > 0;
};

export const currencyStringToNumber = (value: string | undefined | null): number => {
  return value != null ? Number(value.substring(value.search(/[0-9]/))) : 0;
};

export const getCookie = (key: string): string | undefined => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${key}=`))
    ?.split('=')[1];
};

export default setSegmentCookie;
