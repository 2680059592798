import { useQuery } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { COUNTRIES_URL } from 'constants/urls';
import { vatCountries } from 'constants/vatCountries';
import { Country } from 'models/Country';
import { useURLParams } from 'hooks/useURLParams';

// This hook is used retrieve a list of countries in which WPE can accept signups from.
export const useCountries = () => {
  const transactionId = useURLParams('transaction_id');

  const { status, data } = useQuery('countries', () => FetchAPI.get(`${COUNTRIES_URL}/${transactionId}/`));

  // Order by country name
  const countries: Country[] = data?.sort((countryA: Country, countryB: Country) =>
    countryA.name.localeCompare(countryB.name),
  );

  return { status, countries, vatCountries };
};
